<svelte:head>
  <link rel="stylesheet" href="/bootstrap.min.css">
  <link rel="stylesheet" href="/bootstrap-icons.css">
</svelte:head>

<script context="module" lang="ts">
  import type { Load } from "@sveltejs/kit/types/page";
  export const load: Load = async ({ session }) => {
    if (session?.session?.id) {
      return {status: 302,
              redirect: "/"};
    }
    return {fallthrough: true};
  }
</script>

<div class="container">
  <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
    <a sveltekit:prefetch href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
      <img src="/logo.jpg" alt="MYEO">
    </a>
  </header>
  <main>
    <slot></slot>
  </main>
</div>
